<template>
  <div class="column" :class="menuWidth? 'tab1':'tab2'" style="height:100%;">
    <div style="margin-top:30px;">
      <el-container style="height:100%;width: 100%;">
        <el-aside style=" display: flex; justify-content: center; align-items: center;">
          <div class="column day-left">
            <div class="day-margin">剩余{{ dayList.length }}个</div>
            <div v-if="dayList.length>0" class="row day-word">
              <svg
                t="1648444083044"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="3001"
                data-darkreader-inline-fill
                width="16"
                height="16"
                style="cursor:pointer"
                @click="onPronounce(dayList[0].content)"
              >
                <path
                  d="M525.541411 131.503033l-230.494605 148.031475h-114.988917c-63.895239 0-115.679649 51.14996-115.679649 115.219161v230.898811c0 63.664995 51.150984 115.219161 115.679649 115.219161h114.988917l230.494605 147.915841c32.582069 14.128789 57.840336-0.980327 57.840336-33.04358V164.54559c0-32.120558-25.892717-46.825468-57.840336-33.042557z m194.223516 186.033997c-10.841929-11.649318-29.122271-12.283768-40.712237-1.384533-11.649318 10.899234-12.226462 29.122271-1.384533 40.770565 5.363148 5.76633 14.531971 18.511609 23.989367 37.772279 16.146749 32.870642 25.892717 71.449285 25.892717 115.507733 0 44.058448-9.745968 82.694397-25.892717 115.507734-9.457396 19.259646-18.626219 32.004925-23.989367 37.772278-10.899234 11.648294-10.264784 29.871331 1.384533 40.770566 11.648294 10.899234 29.871331 10.264784 40.770566-1.384533 9.054213-9.68764 21.509896-26.987654 33.620725-51.727105 19.895119-40.483017 31.774681-87.654362 31.774681-140.996245 0-53.341882-11.879562-100.514251-31.774681-140.996244-12.169157-24.62484-24.625864-41.983183-33.679054-51.612495z m176.806845-35.69599c-23.297612-39.444361-46.825468-66.778916-63.318094-81.483826-11.880585-10.609639-30.102598-9.572006-40.714284 2.36486-10.609639 11.936867-9.573029 30.101575 2.364861 40.712238 2.709715 2.421142 8.131191 7.842619 15.396665 16.261358 12.455683 14.416338 24.911366 31.601742 36.619012 51.439556 33.504068 56.860009 53.630455 123.349329 53.630455 199.066825s-20.126386 142.206816-53.68776 199.125153c-11.706623 19.836791-24.220634 37.022195-36.619012 51.438533-7.265474 8.41874-12.68695 13.840216-15.397689 16.261359-11.879562 10.610662-12.916171 28.833699-2.363837 40.714283 10.553357 11.879562 28.833699 12.916171 40.713261 2.363838 16.493649-14.70491 40.021505-41.98216 63.319117-81.483826 38.522362-65.336054 61.646012-141.745305 61.646012-228.41934 0.058328-86.614683-23.066345-163.023934-61.588707-228.361011z"
                  fill="#4c4a4a"
                  style="--darkreader-inline-fill: #5a6265;"
                  data-darkreader-inline-fill
                />
              </svg>
              <span v-if="isWordShow" style="cursor:pointer;">{{ dayList[0].content }}</span>
              <span v-else style="cursor:pointer;" @click.stop="isWordShow=true;">
                <span
                  v-for="(item, index) in dayList[0].content.length"
                  :key="index"
                  style="margin-left: 0px;"
                >*</span>
              </span>
            </div>
            <div v-if="dayList.length>0 && dayList[0].en" class="row day-word">
              <svg
                t="1648444083044"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="3001"
                data-darkreader-inline-fill
                width="16"
                height="16"
                style="cursor:pointer"
                @click="onPronounce(dayList[0].en)"
              >
                <path
                  d="M525.541411 131.503033l-230.494605 148.031475h-114.988917c-63.895239 0-115.679649 51.14996-115.679649 115.219161v230.898811c0 63.664995 51.150984 115.219161 115.679649 115.219161h114.988917l230.494605 147.915841c32.582069 14.128789 57.840336-0.980327 57.840336-33.04358V164.54559c0-32.120558-25.892717-46.825468-57.840336-33.042557z m194.223516 186.033997c-10.841929-11.649318-29.122271-12.283768-40.712237-1.384533-11.649318 10.899234-12.226462 29.122271-1.384533 40.770565 5.363148 5.76633 14.531971 18.511609 23.989367 37.772279 16.146749 32.870642 25.892717 71.449285 25.892717 115.507733 0 44.058448-9.745968 82.694397-25.892717 115.507734-9.457396 19.259646-18.626219 32.004925-23.989367 37.772278-10.899234 11.648294-10.264784 29.871331 1.384533 40.770566 11.648294 10.899234 29.871331 10.264784 40.770566-1.384533 9.054213-9.68764 21.509896-26.987654 33.620725-51.727105 19.895119-40.483017 31.774681-87.654362 31.774681-140.996245 0-53.341882-11.879562-100.514251-31.774681-140.996244-12.169157-24.62484-24.625864-41.983183-33.679054-51.612495z m176.806845-35.69599c-23.297612-39.444361-46.825468-66.778916-63.318094-81.483826-11.880585-10.609639-30.102598-9.572006-40.714284 2.36486-10.609639 11.936867-9.573029 30.101575 2.364861 40.712238 2.709715 2.421142 8.131191 7.842619 15.396665 16.261358 12.455683 14.416338 24.911366 31.601742 36.619012 51.439556 33.504068 56.860009 53.630455 123.349329 53.630455 199.066825s-20.126386 142.206816-53.68776 199.125153c-11.706623 19.836791-24.220634 37.022195-36.619012 51.438533-7.265474 8.41874-12.68695 13.840216-15.397689 16.261359-11.879562 10.610662-12.916171 28.833699-2.363837 40.714283 10.553357 11.879562 28.833699 12.916171 40.713261 2.363838 16.493649-14.70491 40.021505-41.98216 63.319117-81.483826 38.522362-65.336054 61.646012-141.745305 61.646012-228.41934 0.058328-86.614683-23.066345-163.023934-61.588707-228.361011z"
                  fill="#5e5e5e"
                  style="--darkreader-inline-fill: #5a6265;"
                  data-darkreader-inline-fill
                />
              </svg>
              <span
                v-if="isSentenceShow"
                style="font-size: 12px;color: #5e5e5e;font-weight: normal;cursor:pointer;"
              >{{ dayList[0].en }}</span>
              <span
                v-else
                style="cursor:pointer;font-size: 12px;color: #5e5e5e;font-weight: normal;cursor:pointer;"
                @click.stop="isSentenceShow=true;"
              >
                <span
                  v-for="(item, index) in dayList[0].en.length"
                  :key="index"
                  style="margin-left: 0px;"
                >*</span>
              </span>
            </div>
            <span v-if="dayList.length==0" class="day-word">没有收藏的单词了</span>
            <div class="day-margin margintop" @click="onNext">Next</div>
            <div class="day-margin margintop" @click="onFind">查询</div>
            <div class="day-margin margintop" @click="onSentence">例句</div>
            <div v-if="scenarioVideoBut" class="day-margin margintop" @click="onScenarioVideo">情景视频</div>
            <div v-if="dayListItem.en" class="day-margin margintop" @click="onBackout">撤销</div>
          </div>
        </el-aside>
        <el-aside style="width: calc(100% - 265px);height: 100%;overflow-x: hidden;overflow-y: hidden;">
          <div style="height:100%;">
            <!-- 查词 -->
            <iframe
              v-if="isFindIframe"
              id="iframe"
              ref="iframe"
              :src="`${service}Sys/YouDaoHtml?word=${selectionValue}`"
              :style="{width:iframeWidth+'px',height:iframeHeight+'px'}"
              frameborder="0"
              style="margin-left:10px;"
            ></iframe>
            <!-- 例句 -->
            <div v-if="isSentence" style="margin-left: 30px;">
              <el-scrollbar
                class="scrollbar"
                :style="{width:iframeWidth+'px',height:iframeHeight+'px'}"
              >
                <div
                  v-for="(item, index) in modelSentenceList"
                  :key="index"
                  class="column ms-row"
                  style="cursor:pointer;"
                  @click.stop="osMSOpacity(index)"
                >
                  <span class="ms-english">
                    {{ index+1 }}.
                    <span
                      style="  -webkit-touch-callout: text;   -webkit-user-select: text; -khtml-user-select: text; -moz-user-select: text; -ms-user-select: text; user-select: text;"
                      v-html="item.english"
                    ></span>
                    <svg
                      data-v-236478fb
                      title="合成音"
                      t="1632040636894"
                      viewBox="0 0 1024 1024"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      p-id="1916"
                      width="14"
                      height="14"
                      style="margin-left:5px;cursor:pointer;"
                      @click.stop="onMsPlay(1,item.englishText)"
                    >
                      <path
                        data-v-236478fb
                        d="M143.36 737.28a81.92 81.92 0 0 1-81.92-81.92V368.64a81.92 81.92 0 0 1 81.92-81.92h163.84l171.6224-148.74624A81.92 81.92 0 0 1 614.4 199.8848v624.2304a81.92 81.92 0 0 1-135.5776 61.91104L307.2 737.28H143.36z m684.83072-515.4816A358.07232 358.07232 0 0 1 962.56 501.76a358.07232 358.07232 0 0 1-134.36928 279.9616 30.72 30.72 0 0 1-38.46144-47.9232 296.63232 296.63232 0 0 0 111.4112-232.0384c0-91.40224-41.472-175.9232-111.4112-232.0384a30.72 30.72 0 1 1 38.46144-47.9232z m-114.9952 121.18016C772.7104 382.09536 808.96 444.14976 808.96 512c0 67.85024-36.2496 129.90464-95.76448 169.02144a30.72 30.72 0 1 1-33.75104-51.32288C722.3296 601.4976 747.52 558.32576 747.52 512s-25.21088-89.51808-68.07552-117.69856a30.72 30.72 0 1 1 33.75104-51.32288z"
                        fill="#646262"
                      />
                    </svg>
                    <svg
                      data-v-236478fb
                      title="原音"
                      t="1632040636894"
                      viewBox="0 0 1024 1024"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      p-id="1916"
                      width="14"
                      height="14"
                      style="margin-left:5px;cursor:pointer;"
                      @click.stop="onMsPlay(2,item)"
                    >
                      <path
                        data-v-236478fb
                        d="M143.36 737.28a81.92 81.92 0 0 1-81.92-81.92V368.64a81.92 81.92 0 0 1 81.92-81.92h163.84l171.6224-148.74624A81.92 81.92 0 0 1 614.4 199.8848v624.2304a81.92 81.92 0 0 1-135.5776 61.91104L307.2 737.28H143.36z m684.83072-515.4816A358.07232 358.07232 0 0 1 962.56 501.76a358.07232 358.07232 0 0 1-134.36928 279.9616 30.72 30.72 0 0 1-38.46144-47.9232 296.63232 296.63232 0 0 0 111.4112-232.0384c0-91.40224-41.472-175.9232-111.4112-232.0384a30.72 30.72 0 1 1 38.46144-47.9232z m-114.9952 121.18016C772.7104 382.09536 808.96 444.14976 808.96 512c0 67.85024-36.2496 129.90464-95.76448 169.02144a30.72 30.72 0 1 1-33.75104-51.32288C722.3296 601.4976 747.52 558.32576 747.52 512s-25.21088-89.51808-68.07552-117.69856a30.72 30.72 0 1 1 33.75104-51.32288z"
                        fill="#c61200"
                      />
                    </svg>
                    <i v-if="item.isMsLoading" class="el-icon-loading" style="margin-left:5px;"></i>
                  </span>
                  <span class="ms-chinese">
                    <span v-html="item.chinese"></span>
                  </span>
                  <span class="ms-provenance">{{ item.grouping }}({{ item.name }})</span>
                </div>
              </el-scrollbar>
            </div>
            <!-- 联想视频 -->
            <!-- 精确搜索 -->
            <div v-if="isScenarioVideo && scenarioVideoBut" style="flex: 1;position: relative;margin-left: 10px;margin-right: 10px;">
              <div class="video column" style="width: 100%;height: 100%;position: relative;">
                <video
                  id="sceneVideo"
                  ref="sceneVideo"
                  oncontextmenu="return false;"
                  style="outline: none;width: 100%;height: 100%;border-radius: 6px;"
                  :src="sceneVideoUrl"
                  controlslist="nodownload"
                  controls
                  @click.left="onsSceneVideoClickLeft"
                  @timeupdate="onTimeupdate"
                ></video>
                <!-- 字幕 -->
                <div class="column" style="position: absolute;bottom: 30px;color: white;width: 100%;display: flex;justify-content: center;align-items: center;">
                  <div v-if="subtitle && subtitle.english" style="background: #000000c2;padding: 5px 10px;border-radius: 8px;font-size: 16px;font-weight: bold;"> <label>{{ subtitle.english }}</label></div>
                  <div v-if="subtitle && subtitle.chinese" style="background: #000000c2;padding: 5px 10px;border-radius: 8px;font-size: 14px;margin-top: 5px;"> <label>{{ subtitle.chinese }}</label></div>
                </div>
              </div>
            </div>
            <!-- 模糊搜索 -->
            <div v-if="isScenarioVideo && scenarioVideoBut" style="flex: 1;position: relative;margin-left: 10px;margin-right: 10px;margin-top: 10px;margin-bottom: 20px;">
              <el-scrollbar style="width: 100%;">
                <div class="row" style="flex-wrap: wrap;">
                  <div
                    v-for="(item,i) in scenedim"
                    :key="item.md5"
                    class="column"
                    style="margin: 5px;border-radius: 30px;font-weight: bold;color: white;border: solid 1px #e8e8e8;"
                    :style="{'background': backgroundColor[i%4]}"
                    @click="onDim(item)"
                  >
                    <!-- <el-image :src="item.cover" fit="cover" class="item-img" lazy style="width: 300px;height: 150px;border-radius: 6px 6px 0px 0px;" /> -->
                    <label style="font-size: 12px;padding: 10px;">{{ item.name }}</label>
                  </div>
                </div>
              </el-scrollbar>
            </div>
          </div>
        </el-aside>
      </el-container>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { list, deWord } from '@/apis/word-library'
import { modelSentence } from '@/apis/subtitle'
import { getAvName } from '@/apis/video'
import { getSubtitle } from '@/apis/subtitle'
import router from '@/router'
var that
export default {
  name: 'Index',
  components: {},
  data() {
    return {
      menuWidth: true,
      service: Vue.$conf.service[Vue.$conf.environment],
      selectionValue: 'Im',
      dayList: [],
      iframeWidth: 1000,
      iframeHeight: 1000,
      isFindIframe: false,
      type: 0,
      date: [],
      audio: {}, // 音频播放
      dayListItem: {}, // 上一个词
      isWordShow: false, // 词是否显示
      isSentenceShow: false, // 句是否显示
      sidx: '', // 排序列
      isSentence: false, // 例句
      modelSentenceList: [],
      isScenarioVideo: false,
      sceneVideoMd5: '',
      sceneVideoSubtitle: [],
      subtitle: {},
      sceneVideoUrl: '',
      sceneVideoIsPlay: true,
      sceneVideoPayState: false,
      scenarioVideoBut: false,
      scenedim: [],
      backgroundColor: ['#f87622', '#30b405', '#d74104', '#0496d7'] // 情景视频相关视频背景色
    }
  },
  mounted() {
    that = this
    that.iframeWidth = 800
    that.iframeHeight = document.documentElement.clientHeight - 120
    that.onOpen(1)
    Vue.$menuShow = function(show) {
      that.menuWidth = show
    }
  },
  created() {
    this.audio = new Audio()
    // 窗口变化
    window.onresize = function() {
      that.iframeWidth = 800
      that.iframeHeight = document.documentElement.clientHeight - 120
    }
    var jurisdiction = JSON.parse(localStorage.getItem('user')).jurisdiction
    if (!jurisdiction.menus['nwb']) {
      router.push({
        name: 'Noaccess'
      })
      return
    }
  },
  methods: {
    // 加载记录
    onOpen(e) {
      that.type = e
      var date = []
      if (e === 3) date = that.date
      list({ type: e, date, page: { sidx: that.sidx } }).then(res => {
        switch (e) {
          case 1:
            that.dayList = res.data
            if (that.dayList.length > 0) {
              that.onPronounce(that.dayList[0].content)
            }
            break
        }
      })
    },
    // 下一个单词
    onNext() {
      if (that.dayList.length > 1) that.dayListItem = that.dayList[0]
      deWord({ uwlId: that.dayList[0].id })
      that.dayList.shift()
      that.isFindIframe = false
      that.isWordShow = false
      that.isSentenceShow = false
      if (that.dayList.length > 0) {
        that.onPronounce(that.dayList[0].content)
      }
    },
    // 查词
    onFind() {
      if (that.dayList.length === 0) return
      that.isFindIframe = true
      this.isSentence = false
      this.isScenarioVideo = false
      that.selectionValue = that.dayList[0].content
    },
    // 撤销
    onBackout() {
      if (that.dayListItem.en) {
        that.dayList.splice(0, 0, that.dayListItem)
        that.dayListItem = {}
        that.isWordShow = false
        that.isSentenceShow = false
        that.onPronounce(that.dayList[0].content)
      }
    },
    // 发音
    onPronounce(text) {
      that.audio.src = `http://dict.youdao.com/speech?audio=${text}`
      that.audio.play()
      that.$nextTick(function() {
        getAvName({
          name: text
        }).then(res => {
          if (res.data.exact) {
            that.sceneVideoUrl = res.data.exact.url
            that.scenarioVideoBut = true
            that.sceneVideoMd5 = res.data.exact.md5
            that.scenedim = res.data.dim
            that.onScenarioVideo()
          } else {
            that.scenarioVideoBut = false
            that.sceneVideoUrl = ''
            that.sceneVideoMd5 = ''
          }
        })
      })
    },
    // 例句
    onSentence() {
      this.isFindIframe = false
      this.isSentence = true
      this.isScenarioVideo = false
      var that = this
      console.log(this.dayList)
      // 查询例句
      modelSentence({ word: that.dayList[0].content.trim() }).then(res => {
        for (let index = 0; index < res.data.length; index++) {
          const element = res.data[index]
          element.englishText = element.english
          var reg = new RegExp(
            `\\b${that.dayList[0].content.trim()}\\b`,
            'i',
            'g'
          )
          element.english = element.english.replace(
            reg,
            `<span style="color: #00a803;opacity: 0;" id="msword${index}">${that.dayList[0].content.trim()}</span>`
          )
          element.english = `<span style="opacity: 0;" id="msEnglish${index}">${element.english}</span>`
          if (element.chinese === null) {
            element.chinese = '无中文字幕'
          }
          element.chinese = `<span style="opacity: 0;" id="msChinese${index}">${element.chinese}</span>`
          element.isMsLoading = false
        }
        that.modelSentenceList = res.data
        that.msLogin = false
      })
    },
    // 合成例句发音
    onMsPlay(type, obj) {
      var that = this
      if (type === 1) {
        this.audio.src = `http://dict.youdao.com/speech?audio=${obj}`
        this.audio.play()
      } else if (type === 2) {
        console.log(obj)
        obj.isMsLoading = true
        this.audio.src = obj.audio
        this.audio.currentTime = obj.startMillisecond / 1000
        var audioTimeupdate = function(event) {
          if (that.audio.currentTime >= obj.endMillisecond / 1000) {
            that.audio.pause()
            that.audio.removeEventListener('timeupdate', audioTimeupdate)
            that.audio.removeEventListener('canplay', canplay)
          }
        }
        var canplay = function() {
          obj.isMsLoading = false
        }
        this.audio.play()
        this.audio.addEventListener('timeupdate', audioTimeupdate)
        this.audio.addEventListener('canplay', canplay)
      }
    },
    // 例句隐藏
    osMSOpacity(index) {
      if (document.getElementById('msEnglish' + index).style.opacity !== '1') {
        document.getElementById('msEnglish' + index).style.opacity = 1
      } else if (
        document.getElementById('msword' + index).style.opacity !== '1'
      ) {
        document.getElementById('msword' + index).style.opacity = 1
      } else if (
        document.getElementById('msChinese' + index).style.opacity !== '1'
      ) {
        document.getElementById('msChinese' + index).style.opacity = 1
      } else {
        document.getElementById('msEnglish' + index).style.opacity = 0
        document.getElementById('msword' + index).style.opacity = 0
        document.getElementById('msChinese' + index).style.opacity = 0
      }
      // // 原生事件 阻止冒泡
      // window.event.cancelBubble = true
    },
    // 联想视频播放
    onScenarioVideo(isplay = false) {
      that.isScenarioVideo = true
      that.isFindIframe = false
      that.isSentence = false
      getSubtitle({ md5: that.sceneVideoMd5 }).then(res => {
        that.sceneVideoSubtitle = res.data
      })
      that.$nextTick(() => {
        // that.$refs.sceneVideo.focus()
        // 播放事件
        // that.$refs.sceneVideo.removeEventListener('play')
        that.$refs.sceneVideo.addEventListener('play', function() {
          that.$refs.sceneVideo.controls = true
          that.sceneVideoIsPlay = true
        })

        // 暂停事件
        // that.$refs.sceneVideo.removeEventListener('pause')
        that.$refs.sceneVideo.addEventListener('pause', function() {
          that.$refs.sceneVideo.controls = false
          that.sceneVideoIsPlay = false
        })
        if (isplay) {
          that.$refs.sceneVideo.play()
        }
      })
    },
    // 情景视频左键点击
    onsSceneVideoClickLeft() {
      if (!this.sceneVideoIsPlay) this.$refs.sceneVideo.play()
    },
    onTimeupdate(e) {
      // console.log(that.$refs.sceneVideo.currentTime)
      const currentMillisecondCeil = Math.ceil(that.$refs.sceneVideo.currentTime * 1000)
      const currentSubtitle = that.sceneVideoSubtitle.find((x, index) => {
        if (x.startMillisecond <= currentMillisecondCeil) {
          // that.currentSubtitleIndex = index
          return true
        }
        return false
      })
      that.subtitle = currentSubtitle
    },
    onDim(item) {
      that.sceneVideoUrl = item.url
      that.scenarioVideoBut = true
      that.sceneVideoMd5 = item.md5
      that.sceneVideoSubtitle = []
      that.subtitle = {}
      that.onScenarioVideo(true)
    }
  }
}
</script>

<style lang="less" scoped>
 video::-webkit-media-controls-fullscreen-button{ display: none !important; }

 .tab1 {
  width: calc(100% - 245px) !important;
  height: calc(100% - 30px) !important;
}
.tab2 {
  width: calc(100% - 45px) !important;
  height: calc(100% - 30px) !important;
}
.row {
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
}
.header {
  background: rgb(198, 18, 0) none repeat scroll 0% 0%;
  display: flex;
  //   justify-content: center;
  align-items: center;
  .image {
    width: 50px;
    height: 50px;
    border-radius: 5px;
    margin-left: 20px;
  }
  span {
    font-size: 14px;
    color: white;
    margin-left: 20px;
  }
}
.day-left {
  margin-left: 10px;
  height: 100%;
  width: 200px;
  .day-margin {
    width: 200px;
    height: 30px;
    background: rgb(238, 238, 238);
    border: 1px solid rgb(197, 197, 197);
    border-radius: 6px;
    // 左右上下居中
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
  }
  .day-word {
    font-size: 16px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 30px;
    color: #4c4a4a;
    span {
      max-width: 180px;
      word-break: break-all;
      margin-left: 5px;
    }
  }
  .margintop {
    margin-top: 10px;
  }
}
.select-date {
  position: absolute;
  left: 0px;
  top: 0px;
}
//所有的滚动条
.scrollbar {
  height: 100%;
  ::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
  }
}
// 例句
.ms-row {
  margin: 10px 0px;
  padding: 5px;
  border-width: 0 0 1px 0;
  border-color: #f9f6f6;
  border-style: solid;
  .ms-english {
    font-weight: bold;
    display: flex;
    align-items: center;
    // justify-content: center;
    color: #646262;
    font-size: 14px;
    margin-bottom: 5px;
  }

  .ms-chinese {
    color: #7d7d7d;
    font-size: 11px;
    margin-bottom: 5px;
  }

  .ms-provenance {
    font-size: 10px;
    color: #9d9d9d;
  }
}
//联想视频视频播放框
.scene-video-popup {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(17, 17, 17, 0.7);
  top: 0px;
  left: 0px;
  z-index: 10000;
  // 左右上下居中
  display: flex;
  justify-content: center;
  align-items: center;
  .video {
    width: 80%;
    height: 80%;
    background: rgb(0, 0, 0);
    border-style: solid;
    border-width: 4px;
    border-color: black;
    position: relative;
    border-radius: 5px;
    video {
      width: 100%;
      height: 100%;
    }
    .control {
      position: absolute;
      bottom: 10px;
      width: 100%;
      // 左右上下居中
      display: flex;
      justify-content: center;
      align-items: center;
      .control-but {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 6px 23px;
        background: #0d0d0dcb;
        border-radius: 5px;
      }
      //播放按钮
      .play {
        width: 40px;
        height: 40px;
        margin-left: 20px;
        margin-right: 20px;
        border-radius: 50%;
        text-align: center;
        vertical-align: middle;
        border: 1px solid #eee;
        box-shadow: 0px 0px 5px white;
        .play-icon {
          width: 30px;
          height: 30px;
          margin-left: 4px;
          margin-top: 4px;
        }
        .pause-icon {
          width: 22px;
          height: 22px;
          margin-top: 8px;
        }
      }
      span {
        padding: 0px 10px;
      }
    }
  }
  .close {
    position: absolute;
    top: 10px;
    width: 30px;
    height: 30px;
    right: 10px;
    background: #0d0d0dcb;
    color: white;
    border-radius: 30px;
    font-size: 14px;
    // padding: 5px;
    // 左右上下居中
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
